import * as React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import ActivationCodeModal from "../../ActivationCode/ActivationCodeModal";
import {AccessMode} from "../../../constants/Constants";
import AccessControlRemoveModal from "../../AccessControl/AccessControlRemoveModal";

const DeviceDetailsSettings = (
    props: {
        accessMode: AccessMode,
        editDeviceForm: () => void,
    }
) => {
    switch (props.accessMode){
        case AccessMode.Administrator:
            return (<Row className="show-grid">
                <Col md={6}>
                    <Button onClick={props.editDeviceForm}>
                        <i className="material-icons">build</i> <FormattedMessage id="device.edit.settings"/>
                    </Button>
                </Col>
                <Col md={6} style={{textAlign: "right"}}>
                    <ActivationCodeModal/>
                </Col>
            </Row>);
        case AccessMode.Readonly:
            return (   <Row>
                <Col>
                    <AccessControlRemoveModal />
                </Col>
            </Row>);
        default:
            return null;
    }
}

export default DeviceDetailsSettings;