import * as React from "react";
import {useEffect, useState} from "react";
import {FormattedMessage} from 'react-intl';
import '../../less/RegisterDevice.css';
import UserContext, {IUserContext} from '../UserContext';
import {Button, Modal} from "react-bootstrap";
import "../../less/ActivationCodeModal.css";
import {IResult} from "../../constants/Constants";
import {ActivationCodeAPI} from "../../api/ActivationCodeAPI";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {DeviceActionCreators} from "../../actions/DeviceActionCreators";
import {IAppStore} from "../../store/configureStore";
import SubmitButton from "../SubmitButton";

interface IActivationCodeModalProps {
    deviceId: number;
    fetchSingleDevice: (deviceId: number) => {}
}

interface ActivationCodeModalState {
    codeValue: string,
    validationError: string,
    isSuccessed: boolean,
}

const ActivationCodeModal = (props: IActivationCodeModalProps) => {
    const userContext = React.useContext<IUserContext>(UserContext);

    const [modelState, setModelState] = useState<ActivationCodeModalState>(
        {
            codeValue: null, 
            validationError: null,
            isSuccessed: true
        });
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    useEffect(() => {
        resetFields();
        return () => {
            resetFields();
        }

    }, []);

    const resetFields = () => setModelState({codeValue: null, validationError: null, isSuccessed: true});
    const handleInputChange = (event: React.FormEvent<any>) => {
        const target = event.target as any;
        const value = target.value;
        const isValidated = isValidCode(value);
        
        setModelState({
            codeValue: value,
            validationError: isValidated ? null : "device.activation-code.format.error",
            isSuccessed: isValidated
        });
    }

    const closeDialog = () => {
        resetFields();
        setModalVisible(false);
    }
    const openDialog = () => setModalVisible(true);

    const isValidCode = (value: string) => {
        const key = (value || "");
        return isValidGUID(key);
    }

    const isValidGUID = (value: string): boolean => {
        if (value.length > 0) {
            if ((/^[0-9A-F]{8}\-[0-9A-F]{4}\-[0-9A-F]{4}\-[0-9A-F]{4}\-[0-9A-F]{12}$/).test(value)) {
                return true;
            }
        }
        return false;
    }
    
    const submit = () => {
        ActivationCodeAPI.activateCode({deviceId: props.deviceId, code: modelState.codeValue})
            .then((response: Response) => {
                if (!response.ok) {
                    setModelState({ ...modelState,
                        isSuccessed: false, 
                        validationError: "device.activation-code.errors.process.error" });
                    return Promise.reject(response.statusText);
                }
                return response;
            })
            .then((response: Response) => response.json())
            .then((result: IResult) => {
                setModelState({ ...modelState, isSuccessed: result.isSuccessed, validationError: result.message });
                if (result.isSuccessed) {
                    props.fetchSingleDevice(props.deviceId);
                    closeDialog();
                }
            });
    }

    //Not supported for DCX
   if(userContext.applicationName === "dcx")
       return null;
    
    return (
        <>
            <Button onClick={openDialog}>
                <i className="material-icons">key</i> <FormattedMessage id="device.activation-code.button"/>
            </Button>
            <Modal centered show={modalVisible} onHide={closeDialog} className={"activation-code-modal " +userContext.style}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="device.activation-code.button"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="control-label">
                            <FormattedMessage id="device.activation-code.label"/>
                        </label>
                        <input
                            className={"form-control" + (!modelState.isSuccessed ? " error" : "")}
                            type="text"
                            name="key"
                            placeholder="00000000-0000-0000-0000-000000000000"
                            defaultValue={modelState.codeValue}
                            onChange={handleInputChange}/>
                        {!modelState.isSuccessed && modelState.validationError != null?
                            <span className="code-error"><FormattedMessage id={modelState.validationError}/></span>
                            : null
                        }
                    </div>
                    <div style={{float: "right", marginTop: "5px"}}>
                        <SubmitButton onClick={submit} disabled={!modelState.isSuccessed}>
                            <FormattedMessage id="device.edit.activation-code.use"/>
                        </SubmitButton>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

function mapStateToProps(state: IAppStore, ownProps: any) {
    return {
        deviceId: state.deviceDetails.device.deviceId
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(DeviceActionCreators, dispatch)
)(ActivationCodeModal);
